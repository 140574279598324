.home-page.hidden {
  display: none;
}

.name-container {
  width: 230px;
  margin-left: 20px;
}

.name-container > * {
  display: inline-block;
}

.name {
  width: 0;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  animation: typewriterReveal 2.5s steps(11, end) 1s 1 normal forwards;
}

.name-blinker {
  position: relative;
  width: 16px;
  height: 38px;
  bottom: 8px;
  /* left: 0px; */
  border-bottom: 3px solid var(--main);
  visibility: hidden;
  animation: flashingUnderscore 0.4s linear 0s 12 normal none;
}

.flex-container,
.landing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-container {
  width: 100%;
  position: absolute;
  top: 80px;
  justify-content: center;
}

.landing {
  height: calc(100vh - 80px);
}

.main-picture-container {
  overflow: hidden;
  position: relative;
  height: 456px;
  width: 456px;
}

#main-picture {
  width: 450px;
  height: 450px;
  object-fit: cover;
  margin: 3px;
  filter: saturate(1.4);
}

.main-picture-border {
  position: absolute;
}

#main-picture-top-border {
  border-top: 3px solid var(--main);
  width: 0;
  top: 0;
  left: 3px;
  transition: width 0.1s 0.3s linear;
}

#main-picture:hover ~ #main-picture-top-border {
  width: 100%;
  transition: width 0.5s linear;
}

#main-picture-right-border {
  border-right: 3px solid var(--main);
  height: 0;
  top: 3px;
  right: 0;
  transition: height 0.1s 0.2s linear;
}

#main-picture:hover ~ #main-picture-right-border {
  height: 100%;
  transition: height 0.5s 0.5s linear;
}

#main-picture-bottom-border {
  border-bottom: 3px solid var(--main);
  width: 0;
  bottom: 0;
  right: 3px;
  transition: width 0.1s 0.1s linear;
}

#main-picture:hover ~ #main-picture-bottom-border {
  width: 100%;
  transition: width 0.5s 1s linear;
}

#main-picture-left-border {
  border-left: 3px solid var(--main);
  height: 0;
  bottom: 3px;
  left: 0;
  transition: height 0.1s linear;
}

#main-picture:hover ~ #main-picture-left-border {
  height: 453px;
  transition: height 0.5s 1.5s linear;
}

section {
  /* height: 900px; */
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0 40px; */
}

/* section > * {
  padding: 0 40px;
} */

#about-me > p {
  align-self: flex-start;
  text-align: justify;
}

#about-me > * {
  padding: 0 40px;
}

section:nth-child(even) {
  background-color: var(--main);
}

section:nth-child(even) > * {
  color: var(--background);
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skill-container {
  width: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  animation: skillLogoBounce 8s linear 0s infinite normal forwards;
}

.skill-container > img {
  height: 80px;
  filter: grayscale(1);
  transition: filter 0.4s;
  animation: fadeColor 8s linear 0s infinite normal forwards;
}

.skill-container:hover > img {
  filter: none;
}

.skill-container:nth-child(1) > img {
  border-radius: 8px;
}

.skill-container:nth-child(2),
.skill-container:nth-child(2) > img {
  animation-delay: 0.5s;
}

.skill-container:nth-child(3),
.skill-container:nth-child(3) > img {
  animation-delay: 1s;
}

.skill-container:nth-child(4),
.skill-container:nth-child(4) > img {
  animation-delay: 1.5s;
}

.skill-container:nth-child(5),
.skill-container:nth-child(5) > img {
  animation-delay: 2s;
}

.skill-container:nth-child(6),
.skill-container:nth-child(6) > img {
  animation-delay: 2.5s;
}

.skill-container:nth-child(7),
.skill-container:nth-child(7) > img {
  animation-delay: 3s;
}

.skill-container:nth-child(8),
.skill-container:nth-child(8) > img {
  animation-delay: 3.5s;
}

.skill-container:nth-child(9),
.skill-container:nth-child(9) > img {
  animation-delay: 4s;
}

.skill-container:nth-child(10),
.skill-container:nth-child(10) > img {
  animation-delay: 4.5s;
}

.skill-container:nth-child(11),
.skill-container:nth-child(11) > img {
  animation-delay: 5s;
}

.skill-container:nth-child(12),
.skill-container:nth-child(12) > img {
  animation-delay: 5.5s;
}

.skill-container:nth-child(13),
.skill-container:nth-child(13) > img {
  animation-delay: 6s;
}

.skill-container:nth-child(14),
.skill-container:nth-child(14) > img {
  animation-delay: 6.5s;
}

.skill-container:nth-child(15),
.skill-container:nth-child(15) > img {
  animation-delay: 7s;
}

.skill-container:nth-child(16),
.skill-container:nth-child(16) > img {
  animation-delay: 7.5s;
}

@keyframes typewriterReveal {
  from {
    width: 0;
  }
  to {
    width: 212px;
  }
}

@keyframes flashingUnderscore {
  from {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  to {
    visibility: hidden;
  }
}

@keyframes skillLogoBounce {
  from {
    transform: translateY(0);
  }
  6% {
    transform: translateY(-10px);
  }
  12% {
    transform: translateY(0);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeColor {
  from {
    filter: grayscale(1);
  }
  6% {
    filter: none;
  }
  12% {
    filter: grayscale(1);
  }
  to {
    filter: grayscale(1);
  }
}

.projects-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.project {
  /* width: 150px;
  height: 150px; */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}

.project > .thumbnail-container {
  width: 150px;
  height: 150px;
  border: 3px solid var(--red);
  filter: drop-shadow(10px 10px var(--yellow));
  position: relative;
}

.project > .thumbnail-container > video,
.project > .thumbnail-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.project-modal {
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-modal.visible {
  visibility: visible;
}

.project-modal-contents {
  width: 70%;
  height: 0;
  padding-left: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: var(--red);
  overflow: hidden;
}

.project-modal.visible > .project-modal-contents {
  height: 90%;
  transition: height 0.5s;
}

.project-modal-contents > * {
  color: var(--background);
}

.project-modal-contents > p {
  width: calc(100% - 40px);
}

.project-modal-contents a {
  color: var(--background);
}

.project-modal-close-button {
  position: absolute;
  font-size: 40px;
  margin-block-start: 0;
  margin-block-end: 0;
  right: 15px;
  top: 12px;
  transform: rotate(45deg);
  cursor: pointer;
}

.project-modal-video-container {
  /* width: 60vw; */
  /* height: 60vw; */
  width: 35%;
  height: 0;
  padding-bottom: 35%;
  position: relative;
  overflow: hidden;
  border: 5px solid black;
}

.project-modal-contents img {
  width: 70px;
  height: 70px;
}

.project-modal-video-container > video,
.project-modal-video-container > img {
  width: 101%;
  height: 101%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
}

.project-modal-links {
  width: 90%;
  height: 100px;
  border: 1px solid black;
}

.contact-icons-container {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}

.contact-icon {
  display: flex;
  align-items: center;
  /* margin-right: 20px; */
}

.contact-icon:not(:last-child) {
  margin-right: 20px;
}

/* .contact-icon > * {
  color: var(--main);
  
} */

.contact-icon-box {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border: 2px solid var(--main);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  transition: background-color 0.4s;
}

.contact-icon-box:hover {
  background-color: var(--main);
}

.contact-icon-box:hover > i,
.contact-icon-box:hover #cv-box-text {
  color: var(--background);
}

.contact-icon i,
.contact-icon p {
  color: var(--main);
}

.contact-icon a {
  text-decoration: none;
}

.contact-icon i,
#cv-box-text {
  font-size: 24px;
  transition: color 0.4s;
}

#cv-box-text {
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .main-picture-container {
    height: 306px;
    width: 306px;
  }

  #main-picture {
    width: 300px;
    height: 300px;
  }

  .project-modal-contents {
    zoom: 0.9;
  }

  .project-modal-video-container {
    width: 70%;
    padding-bottom: 70%;
  }
}
