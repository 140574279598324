@font-face {
  font-family: "Akkurat Regular";
  src: url("./resources/fonts/Akkurat-Regular.woff2") format("woff2"),
    url("./resources/fonts/Akkurat-Regular.woff") format("woff");
}

@font-face {
  font-family: "Akkurat Bold";
  src: url("./resources/fonts/Akkurat-Bold.woff2") format("woff2"),
    url("./resources/fonts/Akkurat-Bold.woff") format("woff");
}

@font-face {
  font-family: "Akkurat Mono";
  src: url("./resources/fonts/Akkurat-Mono.woff2") format("woff2"),
    url("./resources/fonts/Akkurat-Mono.woff") format("woff");
}

:root {
  --background: #fffcf9;
  --main: #00a08b;
  /* --teal: #00a08b; */
  --blue: #1e90ff;
  --purple: #9b35e8;
  /* --purple: #6E07F3; */
  --yellow: #f4d35e;
  --orange: #ee964b;
  --red: #f95748;
  --green: #06d6a0;
}

html,
body {
  background-color: var(--background);
  scroll-behavior: smooth;
  overscroll-behavior-x: none;
}

body.modal-open {
  overflow: hidden;
}

h1,
h2,
h4 {
  font-family: Courier, monospace;
}

h3,
p {
  font-family: "Akkurat Regular";
}

a {
  color: white;
}

nav {
  width: 90%;
  position: fixed;
  z-index: 1;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

nav > ul {
  list-style-type: none;
  padding: 0 10px;
  background-color: var(--background);
  opacity: 0.9;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

nav > ul > li {
  display: inline-block;
  overflow: hidden;
  padding-top: 5px;
}

nav > ul > li:hover > .nav-underline {
  width: 100%;
}

nav > ul > li:not(:last-child) {
  opacity: 1;
  margin-right: 1rem;
}

nav h4 {
  font-weight: normal;
  margin-block-start: 0;
  margin-block-end: 0;
  cursor: pointer;
}

.nav-to-top-container {
  visibility: hidden;
  padding-right: 15px;
}

.nav-underline {
  height: 0;
  width: 0;
  position: relative;
  border-bottom: 3px solid var(--main);
  transition: width 0.4s;
}

.nav-underline.skills {
  border-bottom-color: var(--yellow);
}

.nav-underline.projects {
  border-bottom-color: var(--orange);
}

.nav-underline.contact {
  border-bottom-color: var(--red);
}

.nav-underline.cv {
  border-bottom-color: var(--purple);
}

.nav-underline.scroll-in-section {
  width: 100%;
}

.arrow-up {
  position: relative;
  width: 0px;
  height: 0px;
  border: 7px solid transparent;
  border-bottom: 10px solid black;
  bottom: 5px;
}
